<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="secretedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/icon22.jpg" />
            <div class="title">Y OJ</div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div>
        <a-dropdown trigger="hover">
          <a-avatar shape="circle">
            <template
              v-if="loginUser && loginUser.userRole as string !== ACCESS_ENUM.NOT_LOGIN"
            >
              <template v-if="userAvatar">
                <img alt="avatar" :src="userAvatar" class="userAvatar" />
              </template>
              <template v-else>
                <a-avatar>
                  <IconUser />
                </a-avatar>
              </template>
            </template>
          </a-avatar>
          <!--登录时 鼠标悬停头像显示个人信息和退出登录，未登录时只显示登录 -->
          <template #content>
            <template
              v-if="loginUser && loginUser.userRole as 
string !== ACCESS_ENUM.NOT_LOGIN"
            >
              <a-doption>
                <template #icon>
                  <icon-idcard />
                </template>
                <template #default>
                  <a-anchor-link @click="handleClick">个人简介</a-anchor-link>
                </template>
              </a-doption>
              <a-doption>
                <template #icon>
                  <icon-poweroff />
                </template>
                <template #default>
                  <a-anchor-link @click="logout">退出登录 </a-anchor-link>
                </template>
              </a-doption>
            </template>
            <template v-else>
              <a-doption>
                <template #icon>
                  <icon-user />
                </template>
                <template #default>
                  <a-anchor-link href="/user/login">登录 </a-anchor-link>
                </template>
              </a-doption>
            </template>
          </template>
        </a-dropdown>
      </div>
    </a-col>
  </a-row>
  <!-- 对话框 -->
  <a-modal v-model:visible="visible" @ok="handleOk" :footer="false">
    <template #title>用户简介</template>
    <div>
      <a-descriptions
        size="mini"
        layout="inline-vertical"
        :data="data"
        bordered
      />
    </div>
    <a-button
      @click="openModalForm(loginUser.id)"
      style="margin-top: 10px"
      :visible="visible2"
    >
      修改个人信息
    </a-button>
  </a-modal>

  <a-modal
    width="30%"
    :visible="visible2"
    placement="right"
    @ok="handleOk2"
    @cancel="closeModel"
    unmountOnClose
  >
    <div style="text-align: center">
      <a-upload
        action="/"
        :fileList="file ? [file] : []"
        :show-file-list="false"
        @change="onChange"
      >
        <!-- :custom-request="uploadAvatar" -->
        <template #upload-button>
          <a-avatar :size="70" shape="circle">
            <img alt="头像" :src="userInfo?.userAvatar" />
          </a-avatar>
        </template>
      </a-upload>
    </div>
    <a-form
      :model="userInfo"
      label-align="right"
      title="个人信息"
      style="max-width: 480px; margin: 0 auto"
    >
      <a-form-item field="名称" label="名称 :">
        <a-input v-model="userInfo.userName" placeholder="请输入用户名称" />
      </a-form-item>
      <a-form-item field="账号" label="账号 :">
        <a-input v-model="userInfo.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="用户角色" label="角色 :">
        <a-select v-model="userInfo.userRole" placeholder="请输入用户角色">
          <a-option value="admin">管理员</a-option>
          <a-option value="user">普通用户</a-option>
        </a-select>
      </a-form-item>
      <a-form-item field="userProfile" label="简介 :">
        <a-textarea v-model="userInfo.userProfile" placeholder="请输入简介" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { routes } from "../router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUM from "../access/accessEnum";
import {
  FileControllerService,
  User,
  UserControllerService,
} from "../../generated";
import moment from "moment";
import { FileItem, Message } from "@arco-design/web-vue";
const router = useRouter();
const secretedKeys = ref(["/"]);

router.afterEach((to, from, failure) => {
  secretedKeys.value = [to.path];
});
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
const store = useStore();
const loginUser = computed(() => store.state.user.loginUser);
const userAvatar = loginUser.value.userAvatar;
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    //根据权限过滤菜单
    if (
      !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});
//退出登录
const logout = () => {
  UserControllerService.userLogoutUsingPost();
  location.reload();
};
//这个变量是干什么的啊
const visible = ref(false);
const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};
const data = computed(() => {
  return [
    {
      label: "用户名称",
      value: loginUser.value.userName,
    },
    {
      label: "id",
      value: loginUser.value.id,
    },
    {
      label: "用户简介",
      value: loginUser.value.userProfile,
    },
    {
      label: "注册时间",
      value: moment(loginUser.value.createTime).format("YYYY-MM-DD hh:mm"),
    },
  ];
});
//弹窗
const visible2 = ref(false);
const userInfo = ref<User>({
  id: 0,
  userName: "",
  userAccount: "",
  userRole: "",
  userProfile: "",
  userAvatar: "",
  createTime: "",
  updateTime: "",
  mpOpenId: "",
  unionId: "",
  isDelete: 0,
});
const openModalForm = async (userId: any) => {
  const res = await UserControllerService.getUserByIdUsingGet(userId);
  if (res.data) {
    userInfo.value = res.data;
  } else {
    userInfo.value = {
      id: 0,
      userName: "",
      userAccount: "",
      userRole: "",
      userProfile: "",
      userAvatar: "",
      createTime: "",
      updateTime: "",
      mpOpenId: "",
      unionId: "",
      isDelete: 0,
    };
  }
  visible2.value = true;
};
/**
 * 确定修改按钮
 */
// 从表单中获取的用户头像
let userAvatarImg = userInfo.value?.userAvatar;
const handleOk2 = async () => {
  const res = await UserControllerService.updateUserUsingPost({
    ...userInfo.value,
    userAvatar: userAvatarImg,
  });
  if (res.code === 0) {
    Message.success("更新成功！");
    visible2.value = false;
    location.reload();
  } else {
    Message.error("更新失败！", res.msg);
  }
};
const closeModel = () => {
  visible2.value = false;
};
const file = ref();
const onChange = async (fileList: FileItem[], currentFile: FileItem) => {
  file.value = {
    ...currentFile,
  };
};
//上传头像
/**
 * 上传头像
 */
const uploadAvatar = async () => {
  const res = await FileControllerService.uploadFileUsingPost(file?.value.file);
  if (res.code === 0) {
    userAvatarImg = res.data;
    Message.success("上传成功，点击确认即可修改头像");
  } else {
    Message.error("上传失败！" + res.data);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-bar {
  display: flex;
  align-items: center;
}
.logo {
  height: 48px;
}
.title {
  color: #444;
  margin-left: 16px;
}
</style>
